import React from "react";

import * as styles from "./styles.module.css";


const Tabs = (props) => {
    const tabs = props.tabs.map((tab, index) => {
        const tabClassName = [styles.tab];

        if (tab.value === props.selected && props.page === 'profile') {
            tabClassName.push(styles.tabSelected);
        }

        if(tab.value === props.selected && props.page === 'club'){
            tabClassName.push(styles.tabSelectedClub)
        }

        return (
            <div
                key={index}
                className={tabClassName.join(" ")}
                onClick={() => {
                    props.onClick(tab.value);
                }}
                tabIndex="-1"
                role="button"
            >
                {tab.label}
            </div>
        );
    });

    const tabsClassName = [styles.tabs];

    if (props.uppercase) {
        tabsClassName.push(styles.tabUppercase);
    }

    return (
        <div className={tabsClassName.join(" ")}>
            {tabs}
        </div>
    );
};

Tabs.defaultProps = {
    uppercase: false,
    tabs: [],
    selected: null,

    onClick: () => {},
};

export default Tabs;
