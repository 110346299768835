import React from "react";
import PropTypes from 'prop-types';

import "./media-grid.scss";
import ImageCard from "../ImageCard";


const MediaGrid = (props) => {

   const renderMediaGrid = () => {
      if (!props.isLoaded) {
         return (
            <p style={{ textAlign: "center", fontSize: "50px", color: "#cacaca" }}>
               Loading...
            </p>
         );
      }

      if (props.error) {
         return (
            <div>
               {props.error}
            </div>
         );
      }

      let content = [];

      if (props.mediaData.length > 0) {

         if (props.pageType === "bank") {

            content = props.mediaData.map((data, index) => (
               <ImageCard
                  mediaUrl={data.media.url}
                  mediaType={data.media.type}
                  title="ARTIST NAME"
                  subtitle="[AGENCY] LOCATION"
                  description={data.media.title}
                  pageType={props.pageType}
                  index={index}
                  key={index}
               />
            ));
         } else if (props.pageType === "awards") {
            content = props.mediaData.map((data, index) => (
               <ImageCard
                  mediaUrl={data.url}
                  title="ALEKSANDRA WORONIECKA"
                  subtitle="Fashion Creative Director DOCUMENT JOURNAL"
                  description={data.title}
                  pageType={props.pageType}
                  index={index}
                  key={index}
               />
            ));
         }

      }
      return content;
   }

   return (
      <div className={"media-grid " + props.pageType + "-grid"}>
         {renderMediaGrid()}
      </div>
   );

}


MediaGrid.propTypes = {
   pageType: PropTypes.string.isRequired,
   mediaData: PropTypes.array.isRequired,
   isLoaded: PropTypes.bool,
   error: PropTypes.string,
};

export default MediaGrid;