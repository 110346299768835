import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";

import "./footer.scss";

const getCurrentYear = () => {
   return new Date().getFullYear();
}

const Footer = () => {
   return (
      <Router>
         <div className="footer">
            <div className="footer__nav">
               <Link to="/">PRIVACY</Link>  |  <Link to="/">CONTACT</Link>
            </div>
            <div className="footer__copyright club">© {getCurrentYear()} THEAGENTS.CLUB</div>
         </div>
      </Router>
   );
}

export default Footer;