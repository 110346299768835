import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Carousel } from 'react-responsive-carousel';
import ReadMoreReact from 'read-more-react';

import Select from "react-select";

import Video from "../../components/video/index.js";
import Tabs from "../../components/tabs/index.js";

import PortfolioSearchForm from '../../base/forms/profile-search/portfolio.jsx';
import useQuery from "../../base/hooks/use-query/index.js";

import * as api from "../../api";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./profile.scss";


const Profile = () => {
   const allTabs = [
      { value: "portfolio", label: "Portfolio", },
      { value: "profile", label: "Profile", },
      { value: "creative-news", label: "Creative News", },
   ];

   /* -------- */
   const [selectedTab, setSelectedTab] = useState();

   const [playingVideoId, setPlayingVideoId] = useState(null);

   const [profileData, setProfileData] = useState({
      isLoaded: false,
      error: null,
      data: {},
   });

   const [profileGalleryData, setProfileGalleryData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [portfolioCategoriesData, setPortfolioCategoriesData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [portfolioYearsData, setPortfolioYearsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [portfolioMonthsData, setPortfolioMonthsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [artistsGetAllTypesData, setArtistsGetAllTypesData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [profileTabData, setProfileTabData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   /* -------- */

   const params = useParams();

   const history = useHistory();

   const query = useQuery();

   /* -------- */

   const getOptions = (values) => {
      const options = [
         { label: "all", value: "all", },
      ];

      for (let i = 0; i < values.length; i += 1) {
         options.push({
            label: values[i].name,
            value: values[i].slug,
         });
      }

      return options;
   };

   const onLoadPortfolioCategoriesData = async () => {
      const res = await api.portfolioCategoriesGetAll();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.categories);
      } else {
         error = res.error || "Error!";
      }

      setPortfolioCategoriesData({
         isLoaded: true,
         error,
         data,
      });
   };

   const onLoadPortfolioYearsData = async () => {
      const res = await api.portfolioYearsGetAll();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.years);
      } else {
         error = res.error || "Error!";
      }

      setPortfolioYearsData({
         isLoaded: true,
         error,
         data,
      });
   };

   const onLoadPortfolioMonthsData = async () => {
      const res = await api.portfolioMonthsGetAll();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.months);
      } else {
         error = res.error || "Error!";
      }

      setPortfolioMonthsData({
         isLoaded: true,
         error,
         data,
      });
   };

   const onLoadArtistsGetAllTypesData = async () => {
      const res = await api.artistsGetAllTypes();

      let error = null;
      let data = {};

      if (res.ok) {
         data = res.types;
      } else {
         error = res.error || "Error!";
      }

      setArtistsGetAllTypesData({
         isLoaded: true,
         error,
         data,
      });
   };

   const loadProfileTabData = async () => {
      const res = await api.getProfileTabMedia();

      let error = null;
      let data = {};

      if (res.ok) {
         data = res.data;
      } else {
         error = res.error || "Error!";
      }

      setProfileTabData({
         isLoaded: true,
         error,
         data,
      });
   }

   const loadProfileData = async (slug) => {
      const res = await api.getProfileBySlug(slug);

      let error = null;
      let data = {};

      if (res.ok) {
         data = res.data;
      } else {
         error = res.error || "Error!";
      }

      setProfileData({
         isLoaded: true,
         error,
         data,
      });
   };

   /*
   const loadProfileGallery = () => {
      api.getMediaByArtist().then((res) => {
          let error = null;

          if (!res.ok) {
              error = res.error || "Error!";
          }

          setProfileGalleryData({
              isLoaded: true,
              error,
              data: res.data,
          });
      })
   };
   */

   /* -------- */

   const onTabChange = (value) => {
      setSelectedTab(value);
   };

   const openPopup = (post) => {
      // TODO:
   };
   /* -------- */

   const queryParams = {
      category: query.get("creative_categories") || "featured",
      year: query.get("year") || "all",
      month: query.get("month") || "all",
      page: query.get("page") || 1
   };

   const onLoadMediaData = (params) => {
      const {
         category,
         year,
         month,
         newPage,
      } = params;

      //history.push(`${window.location}/profile/ ?category=${category}&year=${year}&month=${month}`)

      //let newPage = page.toString()

      if (params.isNextPage) {
         newPage = (parseInt(newPage) + 1).toString()
      }

      if (params.filterChanged) {
         setPage(1)
         newPage = '1'
      }

      const data = {
         category,
         year,
         month,
         newPage,
      };

      /*
      if (!params.isNextPage) {
         setMediaData({
            isLoaded: false,
            error: '',
            data: [],
            totalEntries: 0
         });
      }
      */

      //loadProfileData(params.slug);

      /*
      api.getSearchBank(data).then((res) => {
         let media = []
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         } else {
            //NOTE: Dummy
            let dummyData = res?.data?.entries?.map((item) => {
               return {
                  artistType: 'photography',
                  category: 'beauty',
                  categoryName: 'Artist profiles',
                  location: 'london',
                  media: {
                     agencies: ['We Folk'],
                     photographer: 'RUO BING LI',
                     clientName: 'X MODERN WEEKLY STYLE',
                     url: item.mediaUrl,
                     mediaPreviewUrl: item.mediaPreviewUrl,
                     clientDate: 'Mar.21',
                     mediaType: item.mediaType
                  },
               };
            });

            media = dummyData || []
         }

         let newMedia = []

         if (params.isNextPage) {
            newMedia = [
               ...mediaData.data
            ]
         }

         newMedia = [
            ...newMedia,
            ...media
         ]

         setMediaData({
            isLoaded: true,
            error,
            data: newMedia || [],
            totalEntries: res.data.totalEntries
         });
      })
      */

   };

   const onCategoryChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         category: event.value,
         filterChanged: true
      });
   };

   const onYearChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         year: event.value,
         filterChanged: true
      });
   };

   const onMonthChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         month: event.value,
         filterChanged: true
      });
   };

   useEffect(() => {
      loadProfileData(params.slug);
      //loadProfileGallery();

      onLoadPortfolioCategoriesData();
      onLoadPortfolioYearsData();
      onLoadPortfolioMonthsData();
      onLoadArtistsGetAllTypesData();
      onLoadMediaData({ ...queryParams });
      loadProfileTabData();
   }, [params.slug]);

   /* -------- */

   const renderVideo = (values) => {
      let canPlay = true;

      if (playingVideoId && values.id !== playingVideoId) {
         canPlay = false;
      }

      return (
         <Video
            canPlay={canPlay}
            height={500}
            width={380}
            type="video/mp4"
            src={values.src}
            onPlayStart={() => {
               setPlayingVideoId(values.id);
            }}
         />
      );
   };

   const renderPost = (post, id, withDescription = true) => {
      const mediaUrl = post?.mediaUrl || "";
      const type = post?.type || "";

      if (type === "video") {
         return renderVideo({
            id,
            src: mediaUrl,
         });
      }

      let contentFooter = "";

      if (withDescription) {
         contentFooter = (
            <div className="image-footer">
               <div className="image-subtitle">
                  ARTIST NAME
               </div>
               <div className="info">
                  [AGENCY] LOCATION
               </div>
            </div>
         );
      }

      return (
         <div className="media-from-list">
            <div
               className={"image-block bank-block"}
               style={{
                  backgroundImage: `url(${mediaUrl})`
               }}
            >
               <div
                  onClick={() => {
                     openPopup(post);
                  }}
               >
               </div>
            </div>
            {contentFooter}
         </div>
      );
   };

   // Temp, TO DO: formik lib and remove this {
   const renderTempPortfolioForm = () => {
      /*
      const selectedWhoOpt = array.findOneByValue(artistsTypes.data, pageState.whoSelectedOption);
      const selectedWhatOpt = array.findOneByValue(categories.data, pageState.whatSelectedOption);
      const selectedWhereOpt = array.findOneByValue(locationsCities.data, pageState.whereSelectedOption);
      */

      const filterStyles = {
         control: (styles, state) => ({
            ...styles,
            backgroundColor: "#fff",
            borderColor: "",
            borderRadius: "",
            borderStyle: "",
            borderWidth: "",
            backgroundColor: "#F592E0",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0.05em",
            textAlign: state.selectProps.textAlign || "left",
            textTransform: "none",
            width: state.selectProps.width || "200px",
            minHeight: "0px",
         }),
         option: (styles) => ({
            ...styles,
            overflowY: "hidden",
            overflowX: "hidden",
            backgroundColor: "white",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0.05em",
            textAlign: "left",
            textTransform: "none",
            color: "black",
         }),
         dropdownIndicator: (styles, state) => ({
            ...styles,
            position: "absolute",
            left: state.selectProps.left || "165px",
            bottom: state.selectProps.bottom || "34px",
         }),
         menu: (styles) => ({
            ...styles,
            backgroundColor: "white",
         }),
      };


      return (
         <form
            action=""
            method="get"
            className="portfolio__form"
         >
            <label htmlFor="category">
               <div>Category</div>
               <Select
                  name="category"
                  styles={filterStyles}
                  options={portfolioCategoriesData.data}
               />
            </label>
            <label htmlFor="year">
               <div>Year</div>
               <Select
                  name="year"
                  styles={filterStyles}
                  options={portfolioYearsData.data}
               />
            </label>
            <label htmlFor="month">
               <div>Month</div>
               <Select
                  name="where"
                  styles={filterStyles}
                  options={portfolioMonthsData.data}
               />
            </label>
         </form>
      );
   };

   const renderTempRosterForm = () => {
      const filterStyles = {
         control: (styles, state) => ({
            ...styles,
            backgroundColor: "#fff",
            borderColor: "",
            borderRadius: "",
            borderStyle: "",
            borderWidth: "",
            backgroundColor: "#F592E0",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0.05em",
            textAlign: state.selectProps.textAlign || "left",
            textTransform: "none",
            width: state.selectProps.width || "200px",
            minHeight: "0px",
         }),
         option: (styles) => ({
            ...styles,
            overflowY: "hidden",
            overflowX: "hidden",
            backgroundColor: "white",
            fontFamily: "Roboto",
            fontSize: "13px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "15px",
            letterSpacing: "0.05em",
            textAlign: "left",
            textTransform: "none",
            color: "black",
         }),
         dropdownIndicator: (styles, state) => ({
            ...styles,
            position: "absolute",
            left: state.selectProps.left || "165px",
            bottom: state.selectProps.bottom || "34px",
         }),
         menu: (styles) => ({
            ...styles,
            backgroundColor: "white",
         }),
      };

      return (
         <form
            action=""
            method="get"
            className="roster__form"
         >
            <label htmlFor="who">
               <div>Who</div>
               <Select
                  name="who"
                  styles={filterStyles}
                  options={getOptions(artistsGetAllTypesData.data)}
               />
            </label>
            <label htmlFor="artist">
               <div>Artist</div>
               <Select
                  name="artist"
                  styles={filterStyles}
                  options={portfolioYearsData.data}
               />
            </label>
         </form>
      );
   };

   // Temp }

   const renderPortfolio = () => {
      if (!profileData.isLoaded) {
         return null;
      }

      const portfolio = profileData.data.portfolio.map((post, index) => {
         return renderPost(post, `portfolio-${index + 1}`);
      });

      return (
         <>
            <div>
               {renderTempPortfolioForm()}
               {renderPortfolioForm()}
            </div>
            <div className="gallery-blocks">
               {portfolio}
            </div>
         </>
      );
   };

   const renderRoster = () => {
      if (!profileData.isLoaded) {
         return null;
      }

      const roster = profileData.data.portfolio.map((post, index) => {
         return renderPost(post, `roster-${index + 1}`, false);
      });

      return (
         <>
            <div>
               {renderTempRosterForm()}
            </div>
            <div className="gallery-blocks">
               {roster}
            </div>
         </>
      );
   }

   const renderProfile = () => {
      if (!profileData.isLoaded) {
         return null;
      }

      //const profileTab = profileTabData.data.map((post, index) => {
      const profileTab = profileData.data.portfolio.map((post, index) => {
         return renderPost(post, `profile-${index + 1}`, false);
      });

      return (
         <>
            <div className="profile-tab-blocks">
               {profileTab}
            </div>
         </>
      );
   };

   const renderCreativeNews = () => {
      if (!profileData.isLoaded) {
         return null;
      }

      const portfolio = profileData.data.portfolio.map((post, index) => {
         return renderPost(post, `cnews-${index + 1}`, false);
      });

      return (
         <>
            <div className="gallery-blocks">
               {portfolio}
            </div>
         </>
      );
   };

   const renderSliderImagePreview = () => {
      if (!profileData.isLoaded) {
         return null;
      }

      const media = profileData.data.portfolio.map((post, index) => {
         return renderPost(post, `slider-${index + 1}`, false);
      });
      return (
         <div className='slider'>
            <Carousel
               emulateTouch="true"
               showIndicators={false}
               renderArrowNext={(onClickHandler, hasNext, label) => (
                  hasNext &&
                  <div className="carousel-arrow-btn control-arrow-next">
                     <svg onClick={onClickHandler} width="51" height="119" viewBox="0 0 51 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="-1" x2="76.9795" y2="-1" transform="matrix(0.639356 -0.768911 0.639356 0.768911 1.78223 118.381)" stroke="#E400B2" strokeWidth="2" />
                        <line y1="-1" x2="76.9795" y2="-1" transform="matrix(-0.639356 -0.768911 0.639356 -0.768911 50.9995 59.1902)" stroke="#E400B2" strokeWidth="2" />
                     </svg>
                  </div>
               )}
               renderArrowPrev={(onClickHandler, hasPrev, label) => (
                  hasPrev &&
                  <div className="carousel-arrow-btn control-arrow-prev">
                     <svg onClick={onClickHandler} width="51" height="119" viewBox="0 0 51 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line y1="-1" x2="76.9233" y2="-1" transform="matrix(-0.639356 -0.768911 -0.639356 0.768911 49.1815 118.381)" stroke="#E400B2" strokeWidth="2" />
                        <line y1="-1" x2="76.9233" y2="-1" transform="matrix(0.639356 -0.768911 -0.639356 -0.768911 0.000244141 59.2334)" stroke="#E400B2" strokeWidth="2" />
                     </svg>
                  </div>
               )}
            >
               {media}
            </Carousel>
            <div className="slider__categories"><span>Agency Web</span><span>Personal Web</span><span>Instagram</span></div>
         </div>
      );
   };

   const renderContactsContent = (type) => {
      if (type === "photographer") {
         return (
            <div className="contacts">
               <div className="col">
                  <div className="contacts__title">[ART+COMMERCE]</div>
                  <div><span className="contacts__location">LONDON</span> +44 (0) 207 031 7301 <a href="#">email</a></div>
                  <div><span className="contacts__location">PARIS</span> +33 1 73 09 98 00 <a href="#">email</a></div>
               </div>
               <div className="col">
                  <div className="contacts__title">[MANAGEMENT ARTISTS]</div>
                  <div><span className="contacts__location">NEW YORK</span> +1 212 206 0737 <a href="#">email</a></div>
               </div>
            </div>
         )
      } else {
         return (
            <div className="contacts">
               <div className="col">
                  <div>Becky Lewis <a href="#">email</a></div>
                  <div>Nadine Javier Shah <a href="#">email</a></div>
                  <div>Philippe Brutus <a href="#"></a>email</div>
                  <div>Anne Kennedy <a href="#">email</a></div>
                  <div>Jimmy Moffat <a href="#">email</a></div>
                  <div>Annemiek Ter Linden <a href="#">email</a></div>
               </div>
               <div className="col">
                  <div>Nicole LePage Tejada <a href="#">email</a></div>
                  <div>Billy Albores <a href="#">email</a></div>
                  <div>Jessica Daly <a href="#">email</a></div>
                  <div>Yael Peres <a href="#">email</a></div>
                  <div>Patrick Stretch <a href="#">email</a></div>
               </div>
            </div>
         )
      }
   }

   const renderTabsContent = () => {
      if (selectedTab === "portfolio") {
         return renderPortfolio();
      }

      if (selectedTab === "roster") {
         return renderRoster();
      }

      if (selectedTab === "profile") {
         return renderProfile();
      }

      if (selectedTab === "creative-news") {
         return renderCreativeNews();
      }

      return null;
   };

   const renderPortfolioForm = () => {
      function renderForm() {
         const initialValues = {
            category: queryParams.category,
            year: queryParams.year,
            month: queryParams.month,
         };

         return (
            <PortfolioSearchForm
               initialValues={initialValues}
               categoryOptions={categoryData.data}
               yearOptions={yearData.data}
               monthOptions={monthData.data}
               onCategoryChange={onCategoryChange}
               onYearChange={onYearChange}
               onMonthChange={onMonthChange}
            />
         )
      }
   }

   const renderTabs = (type) => {
      let configTabs = [];

      if (type === "agency") {
         configTabs = [
            { value: "roster", label: "Roster", },
            { value: "profile", label: "Profile", },
            { value: "creative-news", label: "Creative News", },
         ];
      } else {
         configTabs = [
            { value: "portfolio", label: "Portfolio", },
            { value: "profile", label: "Profile", },
            { value: "creative-news", label: "Creative News", },
         ];
      }

      // set default tab Roster or Portfolio
      if (!selectedTab) {
         setSelectedTab(configTabs[0].value);
      }

      return (
         <>
            <Tabs
               uppercase
               selected={selectedTab}
               tabs={configTabs}
               onClick={onTabChange}
               page='profile'
            />
         </>
      )
   }

   const renderContent = () => {
      const InstagramPreviewDescr = `Photographer Rafael Pavarotti was born in Brazil’s Amazon rainforest in 1993.
         His earliest photographs were made in collaboration with his young friends, who pooled their money to purchase film shot along the beaches and around the abandoned buildings of their locale.
         At 16, Pavarotti left his remote hometown, committed to establishing a professional practice`;

      return (
         <>
            <section>
               <div className='heading'>
                  <h2>{profileData.data.title}</h2>
                  <div className="subtitle-type">{profileData.data.type} / DIRECTOR</div>
                  <div className="subtitle-category">{ /* profileData.data.category */}</div>
               </div>
               <div className='profile-page__wrap'>
                  {renderSliderImagePreview()}
                  <div className="instagram-preview">
                     <div className="instagram-preview__logo" style={{ backgroundImage: `url(${profileData.data.instagramLogoUrl})` }}></div>
                     <div className="instagram-preview__descr">
                        <ReadMoreReact text={InstagramPreviewDescr} />
                     </div>
                  </div>
                  {renderContactsContent(profileData.data.type)}
               </div>
            </section>
            <section>
               {renderTabs(profileData.data.type)}
            </section>
            <section>
               {renderTabsContent()}
            </section>
         </>
      );
   };

   if (!profileData.isLoaded) {
      return (
         <div className="list-page">
            <p
               style={{
                  textAlign: "center",
                  fontSize: "50px",
                  color: "#cacaca",
               }}
            >
               Loading...
            </p>
         </div>
      );
   }

   return (
      <div className="profile-page">
         {renderContent()}
      </div>
   );
};

export default Profile;
