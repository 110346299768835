@media (min-width: 768px) and (max-width: 1023px) {
    .tabs {
        grid-auto-flow: row;
    }
}

@media (min-width: 1024px) {
    .tabs {
        grid-auto-flow: column;
    }
}


.tabs {
    display: grid;
    /* grid-auto-flow: column; */
    align-items: center;
}

.tab {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 30px;

    text-align: center;
    letter-spacing: 0.15em;

    padding: 15px;

    color: #000;

    cursor: pointer;
    outline: none;

    white-space: nowrap;
}

.tabSelected {
    color: #e400b2;
}

.tabSelectedClub {
    color: #06DFED;
}

.tabUppercase {
    text-transform: uppercase;
}

@media screen and (max-width: 500px){
    .tab {
        font-size: 18px;
    }
}
