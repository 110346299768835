import React from "react";

import Footer from "../../components/Footer";
import Header from "../../components/Header";

import routesRoot from "../../routes/root";


const RootLayout = () => {
   let isHomePage = false;
   const pathname = window.location.pathname;
   if (pathname === "/" || pathname === "") {
      isHomePage = true;
   }

   return (
      <>
         <Header
            isHome={isHomePage}
         />

         <div>
            {routesRoot}
         </div>

         <Footer />
      </>
   );
};

export default RootLayout;