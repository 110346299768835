import React from "react";
import { BrowserRouter, Route } from "react-router-dom";

import RootLayout from "../views/RootLayout";


const configureRoutes = () => {
   return (
      <BrowserRouter>
         <Route path="/" component={RootLayout} />
      </BrowserRouter>
   );
};

export default configureRoutes;