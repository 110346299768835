import React, { useState, useEffect } from 'react'

import Section from '../Section'
import { getClubForHomepage } from '../../api'
import './club-section.scss'

export default function ClubSection() {
    const [clubData, setClubData] = useState({
        isLoaded: false,
        error: null,
        data: []
    })

    async function loadData() {
        const res = await getClubForHomepage()

        let error = null
        if (!res.ok) {
            error = res.error || 'Error'
        }

        setClubData({
            isLoaded: true,
            error,
            data: res.data.entries
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    const header = (
        <p className='club-header'>
            The Agents Club is dedicated to the most sought-after photo agencies worldwide and the exceptional image-makers they represent.
        </p>
    )

    return (
        <Section
            isLoaded={clubData.isLoaded}
            error={clubData.error}
            data={clubData.data}
            blockType="club"
            title="The Club"
            subtitle="ICONIC VISUAL ARTIST REPS NETWORK"
            header={header}
        />
    )
}

