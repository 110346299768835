import React from "react";
import { Formik } from "formik";

import Select, { components } from "react-select";
import { AiFillCaretDown } from "react-icons/ai";

import array from "../../lib/array.js"

const filterStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#fff",
    borderColor: "",
    borderRadius: "",
    borderStyle: "",
    borderWidth: "",
    backgroundColor: "#B9F100",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "15px",
    letterSpacing: "0.05em",
    textAlign: state.selectProps.textAlign || "left",
    textTransform: "none",
    width: state.selectProps.width || "200px",
    minHeight: "0px",
  }),
  option: (styles) => ({
    ...styles,
    overflowY: "hidden",
    overflowX: "hidden",
    backgroundColor: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "15px",
    letterSpacing: "0.05em",
    textAlign: "left",
    textTransform: "none",
    color: "black",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    position: "absolute",
    left: state.selectProps.left || "165px",
    bottom: state.selectProps.bottom || "34px",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "white",
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <AiFillCaretDown
        color="#B9F100"
        fontSize={props.selectProps.sizeOfArrow || "30px"}
      />
    </components.DropdownIndicator>
  );
};

function getValue(selected, options){
  const selectedValue = selected
  ? array.findOneByValue(options, selected)
  : null;

  return selectedValue
}

const PortfolioSearchForm = (props) => {
  const renderForm = (formProps) => {
    const { values, setFieldValue, handleSubmit } = formProps;

    return (
      <form onSubmit={handleSubmit}>        
        <div className="portfolio_block__search">
          <div className="profile__form">
            <label htmlFor="category">
              <div className="label">Category</div>
              <Select
                name="category"
                options={props.categoryOptions}
                value={getValue(values.artist, props.categoryOptions)}
                onChange={(value) => {
                  setFieldValue("category", value);
                  props.onCategoryChange(value);
                }}
                styles={filterStyles}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="year">
              <div className="label">Year</div>
              <Select
                name="year"
                options={props.yearOptions}
                value={getValue(values.year, props.yearOptions)}
                onChange={(value) => {
                  setFieldValue("year", value);
                  props.onYearChange(value);
                }}
                styles={filterStyles}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="month">
              <div className="label">Month</div>
              <Select
                name="month"
                options={props.monthOptions}
                value={getValue(values.month, props.monthOptions)}
                onChange={(value) => {
                  setFieldValue("month", value);
                  props.onMonthChange(value);
                }}
                styles={filterStyles}
                components={{ DropdownIndicator }}
              />
            </label>            
          </div>          
        </div>
      </form>
    );
  };

  return (
    <>
      <h1>debug</h1>
      <Formik
        enableReinitialize
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
      >
        {renderForm}
      </Formik>
    </>
  );
};

PortfolioSearchForm.defaultProps = {
  initialValues: {},
  categoriesOptions: [],
  yearsOptions: [],
  monthOptions: [],

  onCategoriesChange: () => {},
  onYearsChange: () => {},
  onMonthsChange: () => {},
  
  onSubmit: () => {},
};

export default PortfolioSearchForm;
