import React from "react";
import PropTypes from "prop-types";

import ImageBlock from "../ImageBlock";
import "./section.scss";
import useDimensions from "../../base/hooks/use-dimensions/index.js";


const Section = (props) => {
   const dimensions = useDimensions();

   // TODO: project?
   const generateAgencyString = (agencies) => {
      if (!agencies.length) {
         return "";
      }

      return agencies.map(aName => `["${aName}"]`);
   };

   const renderContent = () => {
      if (!props.isLoaded) {
         return (
            <p style={{ textAlign: "center", fontSize: "50px", color: "#cacaca" }}>
               Loading...
            </p>
         );
      }

      if (props.error) {
         return (
            <div>
               {props.error}
            </div>
         );
      }

      const imageBlocks = (props.data || []).map((post, index) => {
         const imageBlockProps = {
            key: index,
            blockType: props?.blockType || "",
            backgroundUrl: post?.media?.url || "",
            title: post?.categoryName || "",
            subtitle: post?.media?.title || "",
            info: post?.media?.publishedDateFormatted || "",
            href: props?.blockType || "",
            artistType: "",
            logoUrl: "",
            location: "",
         };

         if (props.blockType === "mag") {
            imageBlockProps.subtitle = post.media.photographer;
            imageBlockProps.info = generateAgencyString(post.media.agencies);
         }

         if (props.blockType === "awards") {
            imageBlockProps.backgroundUrl = post.url;
            imageBlockProps.title = post.title;
            imageBlockProps.subtitle = "FASHION PHOTOGRAPHY AWARD";
            imageBlockProps.description = "SUB CATEGORY: NAME OF THE WINNER";
         }

         if (props.blockType === "list") {
            imageBlockProps.mediaType = post.mediaType;
            imageBlockProps.mediaId = `list-${index + 1}`;
            imageBlockProps.artistType = post.type;
            imageBlockProps.backgroundUrl = post.mediaUrl;
            imageBlockProps.logoUrl = post.instagramLogoUrl;
            imageBlockProps.title = post.title;
            imageBlockProps.subtitle = post.title;
            imageBlockProps.location = post.location;
            imageBlockProps.info = "[ART-COMMERCE] NEW YORK";
            imageBlockProps.href = post.slug;
         }

         if (props.blockType === "bank") {
            imageBlockProps.mediaType = post.mediaType;
            imageBlockProps.mediaId = `list-${index + 1}`;
            imageBlockProps.backgroundUrl = post.mediaUrl;
            imageBlockProps.info = generateAgencyString([]);
            imageBlockProps.href = post.slug;
            imageBlockProps.title = post.title;
            imageBlockProps.description = 'DESCRIPTION'
         }

         if (props.blockType === "club") {
            const isMobile500 = dimensions.width < 500;

            if (isMobile500 === false) {
               imageBlockProps.mediaType = post.mediaType;
               imageBlockProps.mediaId = `list-${index + 1}`;
               imageBlockProps.backgroundUrl = post.mediaUrl;
               imageBlockProps.logoUrl = post.instagramLogoUrl;
               imageBlockProps.mobile = false
            } else {
               imageBlockProps.mobile = true
               imageBlockProps.title = post.title;
               imageBlockProps.mediaType = post.mediaType;
               imageBlockProps.mediaId = `list-${index + 1}`;
               imageBlockProps.backgroundUrl = post.mediaUrl;
               imageBlockProps.subtitle = "LOCATION";
               imageBlockProps.info = "MEMBER AGENCY"
            }
         }

         return (
            <ImageBlock
               {...imageBlockProps}
            />
         );
      });

      return (
         <div className={props.blockType + "__blocks"}>
            {imageBlocks}
         </div>
      );
   };

   return (
      <section>
         <h2 className={props.blockType}>
            {props.title}
         </h2>
         <p className="subtitle">
            {props.subtitle}
         </p>
         {props.header}
         {renderContent()}
         {props.footer}
      </section>
   );
};

Section.defaultProps = {
   isLoaded: false,
   error: null,

   data: [],

   title: "",
   subtitle: "",
   header: null,
   footer: null,
};

Section.propTypes = {
   isLoaded: PropTypes.bool.isRequired,
   data: PropTypes.array.isRequired,
   blockType: PropTypes.string.isRequired
};

export default Section;