import React from "react";

const Input = (props) => {
    return (
        <input
            id={props.id || props.name}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
        />
    );
};

Input.defaultProps = {
    id: null,
    name: "",
    type: "text",
    placeholder: "",
    value: "",
    onChange: () => {},
    onBlur: () => {},
};

export default Input;
