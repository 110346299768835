import React from 'react'

export default function MagSearchForm() {
    return (
        <>
            <div>Hi!</div>
            <div>Hi!</div>
            <div>Hi!</div>
        </>
    )
}
