import React, { useEffect, useState } from "react";

import Section from "../Section";

import { getAwardsForHomepage } from "../../api";
import awardsLogo from '../../img/awards-logo.png'
import "./awards-section.scss";

const AwardsSection = () => {

   const [awardsData, setAwardsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const loadAwardsData = () => {
      getAwardsForHomepage().then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         setAwardsData({
            isLoaded: true,
            error,
            data: res.data,
         });
      })
   }

   useEffect(() => {
      loadAwardsData();
   }, []);

   const header = (
      <div className="awards-info">
         <div className="awards-info__logo">
            <div>
               <img src={awardsLogo} />
            </div>
            <div>
               <img src={awardsLogo} />
            </div>
         </div>
      </div>
   );

   return (
      <Section
         isLoaded={awardsData.isLoaded}
         error={awardsData.error}
         data={awardsData.data.media}
         blockType="awards"
         title="The Awards"
         header={header}
         subtitle="THE AGENTS CLUB PHOTOGRAPHY AWARDS ‘20 & ‘21"
      />
   );
}

export default AwardsSection;