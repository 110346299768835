import React from "react";
import PropTypes from 'prop-types';

import "./image-card.scss";


const ImageCard = (props) => {

   const renderImageHeader = () => {
      let header = null;

      if (!props.pageType === "list" || !props.pageType === "awards") {
         header = (
            <div className="image-header">
               <div className="title">{props.title}</div>
               <span className="subtitle">{props.subtitle}</span>
            </div>
         );
      }

      return header;
   }

   const renderImageFooter = () => {
      let content = null;

      if (props.pageType === "bank") {
         content = (
            <div className="image-footer">{props.description}</div>
         );
      }

      return (content);
   }

   const renderImage = () => (
      <img src={props.mediaUrl} alt={props.pageType + " image"} />
   )


   return (

      <div className={"image-card " + "card-" + props.index} >

         {renderImageHeader()}
         {renderImage()}
         {renderImageFooter()}

      </div>
   );

}

ImageCard.propTypes = {
   pageType: PropTypes.string.isRequired,
   mediaUrl: PropTypes.string.isRequired,
   mediatype: PropTypes.string,
   title: PropTypes.string,
   subtitle: PropTypes.string,
   description: PropTypes.string,
   index: PropTypes.number
};

export default ImageCard;