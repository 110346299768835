import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";

import useQuery from "../../base/hooks/use-query/index.js";

import Section from "../../components/Section";

import ListSearchForm from "../../base/forms/list-search/index.js";

import * as api from "../../api/index.js";

import "./list.scss";


const List = () => {
   const [pageState, setPageState] = useState({
      filtersIsClose: false,
   });

   const [listData, setListData] = useState({
      isLoaded: false,
      error: null,
      // TODO: rename to entries
      artists: [],
      totalEntries: 0,
   });

   const [artistsTypes, setArtistsTypes] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [locationsCities, setLocationsCities] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [categories, setCategories] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   /* -------- */

   const history = useHistory();
   const query = useQuery();

   // TODO: React.useMemo?
   const queryParams = {
      who: query.get("who") || "all",
      what: query.get("what") || "all",
      where: query.get("where") || "all",
      name: query.get("name") || "",
      page: query.get("page") || 1,
   };

   /* -------- */

   const getOptions = (values) => {
      const options = [
         { label: "all", value: "all", },
      ];

      for (let i = 0; i < values.length; i += 1) {
         const val = values[i];

         options.push({
            value: val.slug,
            label: val.name,
         });
      }

      return options;
   };

   /* -------- */

   const loadListData = (params) => {
      const {
         who,
         what,
         where,
         name,
         page,
      } = params;

      history.push(`/list?who=${who}&what=${what}&where=${where}&name=${name}`);

      if (!params.isNextPage) {
         setListData({
            isLoaded: false,
            error: '',
            artists: [],
            totalEntries: 0,
         });
      }

      api.getSearchList({
         type: who,
         category: what,
         location: where,
         name,
         page,
      }).then((res) => {
         let artists = [];
         let totalEntries = 0;

         let error = null;

         if (res.ok) {
            artists = res?.data?.entries || [];
            totalEntries = res?.data?.totalEntries || 0;
         } else {
            error = res.error || "Error!";
         }

         let newArtists = [];

         if (params.isNextPage) {
            newArtists = [
               ...listData.artists,
            ];
         }

         newArtists = [
            ...newArtists,
            ...artists,
         ];

         setListData({
            isLoaded: true,
            artists: newArtists,
            totalEntries,
            error,
         });
      });
   };

   const loadArtistsTypes = () => {
      api.artistsGetAllTypes().then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         if (res.types !== 'undefined') {
            const options = getOptions(res.types);

            setArtistsTypes({
               isLoaded: true,
               error,
               data: options,
            });
         }
      });
   };

   const loadCategories = () => {
      api.getAllCategories().then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         if (res.categories !== undefined) {
            setCategories({
               isLoaded: true,
               error,
               data: getOptions(res.categories),
            });
         }
      });
   };

   const loadLocationsCities = () => {
      api.locationsCitiesGetAll().then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         if (res.cities !== undefined) {
            setLocationsCities({
               isLoaded: true,
               error,
               data: getOptions(res.cities),
            });
         }
      });
   };

   /* -------- */

   useEffect(() => {
      loadArtistsTypes();
      loadCategories();
      loadLocationsCities();

      const qName = query.get("name") || "";

      if(qName.length > 0) {
         setPageState((prev) => ({
            ...prev,
            filtersIsClose: true,
         }));
      }

      loadListData({
         ...queryParams,
      });
   }, []);

   /* -------- */

   const onFieldNameChange = (value) => {
      if(value.length > 0) {
         setPageState((prev) => ({
            ...prev,
            filtersIsClose: true,
         }));

         loadListData({
            ...queryParams,
            name: value,
            who: '',
            what: '',
            where: '',
            page: '',
         });
      } else {
         setPageState((prev) => ({
            ...prev,
            filtersIsClose: false,
         }));

         loadListData({
            ...queryParams,
            name: '',
         });
      }

   };

   const onFieldWhoChange = (value) => {
      loadListData({
         ...queryParams,
         who: value,
      });
   };

   const onFieldWhatChange = (value) => {
      loadListData({
         ...queryParams,
         what: value,
      });
   };

   const onFieldWhereChange = (value) => {
      loadListData({
         ...queryParams,
         where: value,
      });
   };

   /* -------- */

   const renderForm = () => {
      const initialValues = {
         name: queryParams.name,
         who: queryParams.who,
         what: queryParams.what,
         where: queryParams.where,
      };

      return (
         <ListSearchForm
            initialValues={initialValues}
            whoOptions={artistsTypes.data}
            whatOptions={categories.data}
            whereOptions={locationsCities.data}
            onNameChange={onFieldNameChange}
            onWhoChange={onFieldWhoChange}
            onWhatChange={onFieldWhatChange}
            onWhereChange={onFieldWhereChange}
            filtersIsClose={pageState.filtersIsClose}
         />
      );
   };

   const renderList = () => {
      const loader = (
         <p
            style={{
               textAlign: "center",
               fontSize: "50px",
               color: "#cacaca"
            }}
         >
            Loading...
         </p>
      );

      if (!listData.isLoaded) {
         return loader;
      }

      const totalArtists = listData.artists.length || 0;
      const hasMore = totalArtists < listData.totalEntries;

      console.log(listData);

      return (
         <InfiniteScroll
            dataLength={totalArtists}
            next={() => {
               loadListData({
                  ...queryParams,
                  isNextPage: true,
                  page: queryParams.page + 1,
               });
            }}
            hasMore={hasMore}
            loader={loader}
         >
            <Section
               isLoaded={listData.isLoaded}
               error={listData.error}
               data={listData.artists}
               mediaType={listData.mediaType}
               blockType="list"
               title=""
               subtitle=""
            />
         </InfiniteScroll>
      );
   };

   /* -------- */
   return (
      <div className="list-page">
         <section>
            <div className='heading'>
               <h1>The List</h1>
               <p className="subtitle">
                  THE VISUAL CREATIVE DIRECTORY
               </p>
            </div>
            <div className="list_block__search-view-page">
               <h3>Who's who</h3>
               <div className="whos-descr">
                  ARTISTS REPRESENTATIVES . PHOTOGRAPHERS . DIRECTORS . DIGITAL ARTISTS . ILLUSTRATORS . SERVICE PROVIDERS
               </div>
               {renderForm()}
            </div>
            <div className="search-descr">
               {`There are ${listData.totalEntries} items`}
            </div>
            <div className="list-blocks">
               {renderList()}
            </div>
         </section>
      </div>
   );
};

export default List;