import React from 'react'

import newsLogo from '../../img/news-sign-up-logo.png'
import './news-sign-up.scss'

import NewsSignUp from '../../base/forms/news-sign-up'

export default function NewsSignUp() {
    return (
        <section className='news-sign-up-container'>
            <div className='news-logo'>
                <img src={newsLogo} />
            </div>
            <NewsSignUp>
                <div className='news-sign-up-text-container'>
                    <p className='news-sign-up-title'>GET INSPIRED!</p>
                    <p>with the latest creative news from theagents.club</p>
                </div>
            </NewsSignUp>
        </section>
    )
}
