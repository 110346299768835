import React from "react";
import ReactDOM from "react-dom";

import configureRoutes from "./routes";
// import Home from "./views/home";
import "./styles/main.scss";


const main = () => {
   const routes = configureRoutes();

   const root = (
      <React.StrictMode>
         {routes}
      </React.StrictMode>
   );

   ReactDOM.render(root, document.getElementById("root"));
}

main();