import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";



import "./awards.scss";
//import awardsLogo21 from "../../img/logo-awards-21.png"
//import awardsLogo20 from "../../img/logo-awards-20.png"
//import bankFormImg from "../../img/the-bank-form-placeholder.png"
import MediaGrid from "../../components/MediaGrid";

import Tabs from "../../components/tabs/index.js";
import { getAwardsForHomepage } from "../../api";


const Awards = () => {
   const allTabs = [
      { value: "about", label: "About", },
      { value: "jury", label: "Jury", },
      { value: "nominees", label: "Nominees", },
      { value: "winners", label: "Winners", },
   ];

   const [selectedTab, setSelectedTab] = useState();

   const onTabChange = (value) => {
      setSelectedTab(value);
   };

   const [mediaData, setMediaData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const loadMediaData = () => {
      getAwardsForHomepage().then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         setMediaData({
            isLoaded: true,
            error,
            data: res.data,
         });
      })
   }

   const renderTabs = () => {
      // set default tab Roster or Portfolio
      if (!selectedTab) {
         setSelectedTab(allTabs[0].value);
      }

      return (
         <>
            <Tabs
               uppercase
               selected={selectedTab}
               tabs={allTabs}
               onClick={onTabChange}
               page='awards'
            />
         </>
      )
   }

   useEffect(() => {
      loadMediaData();
   }, []);

   

   return (
      <div className="awards-page">
         <section>
            <div className="heading">
               <h1>The Awards</h1>
               <p className="subtitle">THE AGENTS CLUB PHOTOGRAPHY AWARDS ‘20 & ‘21</p>
               <div className="description">
                  The Agents Club Photography Awards honors the craft of today's outstanding image making from its members talented photographers, recognizing the most creative personal & commissioned work in Art, Fashion, Beauty, Still-Life & Interiors and Entertainment.
               </div>
            </div>
            { /*
            <div className="awards-logo-list">
               <img src={awardsLogo21} alt="Awards 21 Logo" />
               <img src={awardsLogo20} alt="Awards 20 Logo" />
            </div>
            <div className="alt-menu">
               <Link to="/awards" className="alt-menu__item">The Jury</Link>
               <Link to="/awards" className="alt-menu__item">The Nominees</Link>
               <Link to="/awards" className="alt-menu__item">The Winners</Link>
            </div>
            <div className="alt-description">
               A jury comprised of 24 celebrated industry experts for each award, including top Creatives, Editors, Stylists, Producers, Art Buyers, Casting Directors and more, will be voting for the most artistic and creative nominated photographs to win The Jury Awards.
            </div>
            <MediaGrid
               isLoaded={mediaData.isLoaded}
               error={mediaData.error}
               pageType="awards"
               mediaData={mediaData.data.media}
            />
            */ }
         </section>
         <section>
            {renderTabs()}
         </section>
      </div>
   );
}

export default Awards;