import React from "react";
import { Formik } from "formik";

import Select, { components } from "react-select";
import { AiFillCaretDown } from "react-icons/ai";

import array from "../../lib/array.js"

const inputStyles = {
  width: "200px",
}

const filterStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: "#fff",
    borderColor: "",
    borderRadius: "",
    borderStyle: "",
    borderWidth: "",
    backgroundColor: "#B9F100",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "15px",
    letterSpacing: "0.05em",
    textAlign: state.selectProps.textAlign || "left",
    textTransform: "none",
    width: state.selectProps.width || "200px",
    minHeight: "0px",
  }),
  option: (styles) => ({
    ...styles,
    overflowY: "hidden",
    overflowX: "hidden",
    backgroundColor: "white",
    fontFamily: "Roboto",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "15px",
    letterSpacing: "0.05em",
    textAlign: "left",
    textTransform: "none",
    color: "black",
  }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    position: "absolute",
    left: state.selectProps.left || "165px",
    bottom: state.selectProps.bottom || "34px",
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: "white",
  }),
  clearIndicator: (styles) => ({
    ...styles,
    color: 'white',
    padding: '0px'
  })
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <AiFillCaretDown
        color="#B9F100"
        fontSize={props.selectProps.sizeOfArrow || "30px"}
      />
    </components.DropdownIndicator>
  );
};

function getValue(selected, options){
  let value;

  if(selected?.value){
    value = selected?.value
  }else{
    value = selected || 'all'
  }

  const selectedValue = value
  ? array.findOneByValue(options, value)
  : null;

  return selectedValue
}

const BankSearchForm = (props) => {
  const renderForm = (formProps) => {
    const { values,
            handleChange,
            setFieldValue,
            handleSubmit
    } = formProps;

    return (
      <form onSubmit={handleSubmit}>
        <div className="bank-filter__creative-categories">
          <label htmlFor="creative_categories">
            <Select
              name="creative_categories"
              options={props.creativeCategoriesOptions}
              onChange={(value) => {
                setFieldValue("creative-categories", value);
                props.onCreativeCategoriesChange(value);
              }}
              styles={filterStyles}
              value={getValue(values.creative_categories, props.creativeCategoriesOptions)}
              width="282px"
              left="300px"
              bottom="null"
              sizeOfArrow="50px"
              textAlign="center"
              placeholder="OTHER CREATIVE CATEGORIES"
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "black",
                },
              })}
              components={{ DropdownIndicator, IndicatorSeparator: () => null }}
            />
          </label>
        </div>
        {window.screen.width > 500 ? (
          <p className="subtitle">{props.totalEntries} FASHION MEDIAS</p>
        ) : null}
        <div className="bank_block__search">
          <div className="list__form">
            <label htmlFor="artist">
              <div className="label">Artist</div>
              <input
                name="artist_name"
                style={inputStyles}
                placeholder="Enter name"
                onChange={(evt) => {
                  handleChange(evt);
                  props.onArtistNameChange(evt.target.value);
              }}
              />
              <Select
                name="artist"
                options={props.artistOptions}
                value={getValue(values.artist, props.artistOptions)}
                onChange={(value) => {
                  setFieldValue("artist", value);
                  props.onArtistChange(value);                  
                }}
                styles={filterStyles}
                isClearable={(values?.artist !== 'all' && values?.artist?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}                
              />
            </label>
            <label htmlFor="agency">
              <div className="label">Agency</div>
              <Select
                name="agency"
                options={props.agencyOptions}
                value={getValue(values.agency, props.agencyOptions)}
                onChange={(value) => {
                  setFieldValue("agency", value);
                  props.onAgencyChange(value);
                }}
                styles={filterStyles}
                isClearable={(values?.agency !== 'all' && values?.agency?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="client">
              <div className="label">Client</div>
              <Select
                name="client"
                options={props.clientOptions}
                value={getValue(values.client, props.clientOptions)}
                onChange={(value) => {
                  setFieldValue("client", value);
                  props.onClientChange(value);
                }}
                styles={filterStyles}
                isClearable={(values?.client !== 'all' && values?.client?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="tags">
              <div className="label">Tags</div>
              <Select
                name="tags"
                options={props.tagsOptions}
                value={getValue(values.tags, props.tagsOptions)}
                onChange={(value) => {
                  setFieldValue("tags", value);
                  props.onTagsChange(value);
                }}
                styles={filterStyles}
                isClearable={(values?.tags !== 'all' && values?.tags?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="media_type">
              <div className="label">Media Type</div>
              <Select
                name="media_type"
                options={props.mediaTypeOptions}
                value={getValue(values.media_type, props.mediaTypeOptions)}
                onChange={(value) => {
                  setFieldValue("media-type", value);
                  props.onMediaTypeChange(value);
                }}
                styles={filterStyles}
                isClearable={(values?.media_type !== 'all' && values?.media_type?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="year">
              <div className="label">Year</div>
              <Select
                name="year"
                options={props.yearOptions}
                value={getValue(values.year, props.yearOptions)}
                onChange={(value) => {
                  setFieldValue("year", value);
                  props.onYearChange(value);
                }}
                styles={filterStyles}
                isClearable={(values?.year !== 'all' && values?.year?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="month">
              <div className="label">Month</div>
              <Select
                name="month"
                options={props.monthOptions}
                value={getValue(values.month, props.monthOptions)}
                onChange={(value) => {
                  setFieldValue("month", value);
                  props.onMonthChange(value);
                }}
                styles={filterStyles}
                isClearable={(values?.month !== 'all' && values?.month?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}
              />
            </label>
            <label htmlFor="region">
              <div className="label">Region</div>
              <Select
                name="region"
                options={props.regionOptions}
                value={getValue(values.region, props.regionOptions)}
                onChange={(value) => {
                  setFieldValue("region", value);
                  props.onRegionChange(value);
                }}
                styles={filterStyles}
                isClearable={(values?.region !== 'all' && values?.region?.value !== 'all') ? true : false}
                components={{ DropdownIndicator }}
              />
            </label>
          </div>
          {window.screen.width <= 500 ? (
            <p className="subtitle">{props.totalEntries} FASHION MEDIAS</p>
          ) : null}
        </div>
      </form>
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
    >
      {renderForm}
    </Formik>
  );
};

BankSearchForm.defaultProps = {
  initialValues: {},
  creativeCategoriesOptions: [],
  artistOptions: [],
  agencyOptions: [],
  clientOptions: [],
  tagsOptions: [],
  mediaTypeOptions: [],
  yearOptions: [],
  monthOptions: [],
  regionOptions: [],

  onArtistNameChange: () => {},
  onCreativeCategoriesChange: () => {},
  onArtistChange: () => {},
  onAgencyChange: () => {},
  onClientChange: () => {},
  onTagsChange: () => {},
  onMediaTypeChange: () => {},
  onYearChange: () => {},
  onMonthChange: () => {},
  onRegionChange: () => {},

  onSubmit: () => {},
};

export default BankSearchForm;
