import React from "react";
import "./button.scss";

const Button = (props) => {
  return <button type={props.type}>{props.text}</button>;
};

Button.defaultProps = {
  type: "submit",
  text: "Button",
};

export default Button;
