import React, { useState } from "react";

import "./club.scss";

import Tabs from "../../components/tabs/index.js";

const Club = () => {
   const allTabs = [
      { value: "about", label: "About", },
      { value: "members", label: "Members", },
      { value: "words", label: "Words", },
      { value: "membership", label: "Membership", },
   ];

   const [selectedTab, setSelectedTab] = useState(() => allTabs[0].value);

   const onTabChange = (value) => {
      setSelectedTab(value);
   };

   const renderTabsContent = () => {
      if (selectedTab === "about") {
         return renderAbout();
      }

      if (selectedTab === "members") {
         return renderMembers();
      }

      if (selectedTab === "words") {
         return renderWords();
      }

      if (selectedTab === "membership") {
         return renderMembership();
      }

      return null;
   };

   function renderAbout() {
      return (
         <div className="club-about-container">
            <div className="club-about-center">
               <p>The Agents Club is dedicated to the most sought-after photo agencies worldwide and the exceptional image-makers they represent.</p>
            </div>
            <div className="club-about">
               <p>
                  THEAGENTS.CLUB is an online private members club whose goal is to assemble an international community of professional photographers representatives serving the global creative industry in the fields of media, advertising, fashion, entertainment, music, art and media.
               </p>
               <p>
                  THEAGENTS.CLUB’s mission is to support and promote independent companies who specialize in creative artist representation and management as well as create new synergies to enhance and defend their common business interests and those of the exceptional image-makers they represent.
               </p>
            </div>
         </div>
      )
   }

   function renderMembers() {
      return (
         <div className="club-members-container">
            <p className="club-members">The Agents Club is dedicated to the most sought-after photo agencies worldwide and the exceptional image-makers they represent.</p>
         </div>
      )
   }

   function renderWords() {
      return (
         <div>Words</div>
      )
   }

   function renderMembership() {
      return (
         <div>Membership</div>
      )
   }

   return (
      <div className="club-page">
         <section>
            <h1>The Club</h1>
            <p>Iconic Visual Artist Reps Network</p>
            <div className="club-tabs">
               <Tabs
                  uppercase
                  selected={selectedTab}
                  tabs={allTabs}
                  onClick={onTabChange}
                  page='club'
               />
            </div>
            <div className="club-content">
               {renderTabsContent()}
            </div>
         </section>
      </div>
   );
}

export default Club;