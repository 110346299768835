
const findByFieldName = (arr, fieldName, value) => {
    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; i += 1) {
            const obj = arr[i] || {};

            if (obj[fieldName] === value) {
                return obj;
            }
        }
    }

    return null;
};

const findOneByValue = (arr, value) => {
    return findByFieldName(arr, "value", value);
};


export default {
    findByFieldName,
    findOneByValue,
};
