import React from 'react'

import ImageBlock from "../ImageBlock";
import featured from '../../img/bank-block-placeholder.png'
import './featured.scss'

export default function Featured() {


    return (
        <section className="featured-section">
            <ImageBlock
                blockType="featured"
                title="FEATURED"
                subtitle="ELEONOR MACCOLLUM [ART DEPARTMENT] X NUMERO MAGAZINE"
                mediaType="image"
                backgroundUrl={featured}
            />
        </section>
    )
}
