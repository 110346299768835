import React from "react";
import Select from "react-select";

import array from "../../../base/lib/array.js";

import * as styles from "./styles.module.css";

const filterStyles = {
    control: styles => ({
       ...styles,
       backgroundColor: '#fff',
       borderColor: '',
       borderRadius: '',
       borderStyle: '',
       borderWidth: '',
       backgroundColor: '#f592e0',
       fontFamily: 'Roboto',
       fontSize: '13px',
       fontStyle: 'normal',
       fontWeight: '400',
       lineHeight: '15px',
       letterSpacing: '0.05em',
       textAlign: 'left',
       textTransform: 'none',
       width: 'auto',
    }),
    option: styles => ({
       ...styles,
       overflowY: 'hidden',
       overflowX: 'hidden',
       backgroundColor: '#f592e0',
       fontFamily: 'Roboto',
       fontSize: '13px',
       fontStyle: 'normal',
       fontWeight: '400',
       lineHeight: '15px',
       letterSpacing: '0.05em',
       textAlign: 'left',
       textTransform: 'none',
    }),
};

const SelectCustom = (props) => {
    const selectedValue = props.selected
        ? array.findOneByValue(props.options, props.selected)
        : null;

    return (
        <div className={styles.select}>
            <div className={styles.selectTitle}>
                {props.title}
            </div>
            <Select
                name={props.name}
                value={selectedValue}
                options={props.options}
                styles={filterStyles}
                onChange={(values) => {
                    props.onChange(values.value);
                }}
            />
        </div>
    );
};

SelectCustom.defaultProps = {
    name: "",
    title: "",
    options: [],
    selected: null,
    onChange: () => {},
};

export default SelectCustom;