import React, { useState, useEffect } from "react";
import AwardsSection from "../../components/AwardsSection/AwardsSection";

import BankSection from "../../components/BankSection";
import ListSection from "../../components/ListSection/ListSection";
import MagSection from "../../components/MagSection";
import ClubSection from "../../components/ClubSection/ClubSection";
import NewsSignUp from "../../components/NewsSignUp";
import Featured from '../../components/Featured'

const Index = () => {
  return (
    <div className="main">
      <Featured />
      <BankSection />
      <ListSection />
      <MagSection />
      <AwardsSection />
      <ClubSection />
      <NewsSignUp />
    </div>
  );
};

export default Index;
