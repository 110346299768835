import React, { useEffect, useState, useRef } from "react";

import { useHistory, Link } from "react-router-dom";
import { AiFillCaretDown } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';
import Select, { components } from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";
import { FiPlay } from 'react-icons/fi'

import array from "../../base/lib/array.js";

import Bank from "../../base/project/bank.js";

import useQuery from "../../base/hooks/use-query/index.js";
import useDimensions from "../../base/hooks/use-dimensions/index.js";
import BankSearchForm from '../../base/forms/bank-search/bank.js'

import Video from "../../components/video/index.js";

// temp
import MediaGrid from "../../components/MediaGrid";
import Popup from "../../components/Popup";
import Loading from "../../components/Loading"

//import bankFormImg from "../../img/the-bank-form-placeholder.png"

import * as api from "../../api";

import "./bank.scss";

const BankContainer = () => {
   const [playingVideoId, setPlayingVideoId] = useState(null);

   const [pageState, setPageState] = useState({
      popupIsOpen: false,
      selectedMediaInPopup: null,
   });

   const [mediaData, setMediaData] = useState({
      isLoaded: false,
      error: null,
      data: [],
      totalEntries: 0
   });

   const [creativeCategories, setCreativeCategories] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [artistsData, setArtistsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [advancedArtistsData, setAdvancedArtistsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [agenciesData, setAgenciesData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [clientsData, setClientsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [tagsData, setTagsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [mediaTypesData, setMediaTypesData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [yearsData, setYearsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [monthsData, setMonthsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [regionsData, setRegionsData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const [page, setPage] = useState(1)

   const [savedFilters, setSavedFilters] = useState({})

   const icon = useRef(null)

   /* -------- */

   const history = useHistory();

   const query = useQuery();

   const dimensions = useDimensions();

   /* -------- */

   const getOptions = (values) => {
      const options = [
         { label: "all", value: "all", },
      ];

      for (let i = 0; i < values.length; i += 1) {
         options.push({
            label: values[i].name,
            value: values[i].slug,
         });
      }

      return options;
   };

   const queryParams = {
      creative_categories: query.get("creative_categories") || "featured",
      artist: query.get("artist") || "all",
      agency: query.get("agency") || "all",
      client: query.get("client") || "all",
      tags: query.get("tags") || "all",
      media_type: query.get("media_type") || "all",
      year: query.get("year") || "all",
      month: query.get("month") || "all",
      region: query.get("region") || "all",
      page: query.get("page") || 1
   };

   /* -------- */

   const onLoadMediaData = (params) => {
      const {
         creative_categories,
         artist,
         agency,
         client,
         tags,
         media_type,
         year,
         month,
         region,
      } = params;

      history.push(`/bank?creative_categories=${creative_categories}&artist=${artist}&agency=${agency}&client=${client}&tags=${tags}&media_type=${media_type}&year=${year}&month=${month}&region=${region}`)

      let newPage = page.toString()

      if (params.isNextPage) {
         newPage = (parseInt(newPage) + 1).toString()
      }

      if (params.filterChanged) {
         setPage(1)
         newPage = '1'
      }

      const data = {
         creative_categories,
         artist,
         agency,
         client,
         tags,
         media_type,
         year,
         month,
         region,
         newPage
      };

      if (!params.isNextPage) {
         setMediaData({
            isLoaded: false,
            error: '',
            data: [],
            totalEntries: 0
         });
      }

      api.getSearchBank(data).then((res) => {
         let media = []
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         } else {
            //NOTE: Dummy
            let dummyData = res?.data?.entries?.map((item) => {
               return {
                  artistType: 'photography',
                  category: 'beauty',
                  categoryName: 'Artist profiles',
                  location: 'london',
                  media: {
                     agencies: ['We Folk'],
                     photographer: 'RUO BING LI',
                     clientName: 'X MODERN WEEKLY STYLE',
                     url: item.mediaUrl,
                     mediaPreviewUrl: item.mediaPreviewUrl,
                     clientDate: 'Mar.21',
                     mediaType: item.mediaType
                  },
               };
            });

            media = dummyData || []
         }

         let newMedia = []

         if (params.isNextPage) {
            newMedia = [
               ...mediaData.data
            ]
         }

         newMedia = [
            ...newMedia,
            ...media
         ]

         setMediaData({
            isLoaded: true,
            error,
            data: newMedia || [],
            totalEntries: res.data.totalEntries
         });
      })
   };

   const onLoadArtists = async () => {
      const res = await api.getArtists();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.artists);
      } else {
         error = res.error || "Error!";
      }

      setArtistsData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadAgencies = async () => {
      const res = await api.getAgencies();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.agencies);
      } else {
         error = res.error || "Error!";
      }

      setAgenciesData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadClients = async () => {
      const res = await api.getClients();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.clients);
      } else {
         error = res.error || "Error!";
      }

      setClientsData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadTags = async () => {
      const res = await api.getTags();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.tags);
      } else {
         error = res.error || "Error!";
      }

      setTagsData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadTypes = async () => {
      const res = await api.getMediaTypes();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.types);
      } else {
         error = res.error || "Error!";
      }

      setMediaTypesData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadYears = async () => {
      const res = await api.getYears();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.years);
      } else {
         error = res.error || "Error!";
      }

      setYearsData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadMonths = async () => {
      const res = await api.getMonths();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.months);
      } else {
         error = res.error || "Error!";
      }

      setMonthsData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadRegions = async () => {
      const res = await api.getRegions();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.regions);
      } else {
         error = res.error || "Error!";
      }

      setRegionsData({
         isLoaded: true,
         data,
         error,
      });
   };

   const onLoadCategories = async () => {
      const res = await api.getOtherCategories();

      let error = null;
      let data = {};

      if (res.ok) {
         data = getOptions(res.categories);
      } else {
         error = res.error || "Error!";
      }

      setCreativeCategories({
         isLoaded: true,
         data,
         error,
      });
   };

   /* -------- */

   const onFieldArtistNameChange = (evt) => {
      const data = {
         search: evt,
         type: "artists",
      };

      api.advancedArtistsSearch(data).then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         setAdvancedArtistsData((prev) => ({
            ...prev,
            data: getOptions(res.artists),
            isLoaded: true,
         }));

      });
   };

   const onCreativeCategoriesChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         creative_categories: event.value,
         filterChanged: true
      });
   };

   const onArtistChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         artist: event?.value || 'all',
         filterChanged: true
      });
   };

   const onAgencyChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         agency: event?.value || 'all',
         filterChanged: true
      });
   };

   const onClientChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         client: event?.value || 'all',
         filterChanged: true
      });
   };

   const onTagsChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         tags: event?.value || 'all',
         filterChanged: true
      });
   };

   const onMediaTypeChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         media_type: event?.value || 'all',
         filterChanged: true
      });
   };

   const onYearChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         year: event?.value || 'all',
         filterChanged: true
      });
   };

   const onMonthChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         month: event?.value || 'all',
         filterChanged: true
      });
   };

   const onRegionChange = (event) => {
      onLoadMediaData({
         ...queryParams,
         region: event?.value || 'all',
         filterChanged: true
      });
   };

   const onOpenPopup = (post) => {
      const isMobile500 = dimensions.width < 500;
      setSavedFilters(queryParams)

      if (isMobile500) return

      setPageState((prev) => ({
         ...prev,
         selectedMediaInPopup: post,
         popupIsOpen: true,
      }));
   }

   const onClosePopup = (event) => {
      if (!event.target.matches('.popup__container') && event?.key !== 'Escape' && !event.target.matches('.media-bank-popup__close-btn') && icon.current.children[0].children[0] != event.target) return

      history.push(`/bank?creative_categories=${savedFilters.creative_categories}&artist=${savedFilters.artist}&agency=${savedFilters.agency}&client=${savedFilters.client}&tags=${savedFilters.tags}&media_type=${savedFilters.media_type}&year=${savedFilters.year}&month=${savedFilters.month}&region=${savedFilters.region}`)

      setPageState((prev) => ({
         ...prev,
         selectedMediaInPopup: null,
         popupIsOpen: false,
      }));
      window.removeEventListener('keydown', onClosePopup)
   }

   /* -------- */

   useEffect(() => {
      onLoadArtists();
      onLoadAgencies();
      onLoadClients();
      onLoadTags();
      onLoadTypes();
      onLoadYears();
      onLoadMonths();
      onLoadRegions();
      onLoadCategories();

      onLoadMediaData({ ...queryParams });
   }, []);

   /* -------- */

   const renderMediaInPopup = () => {
      if (!pageState.selectedMediaInPopup) {
         return (
            <Loading />
         );
      }

      const post = pageState.selectedMediaInPopup;

      if (post.media.mediaType === "video") {
         const index = post.media.url;
         return (
            <div>
               <h3>{post.photographer}</h3>
               <div className="popup-image-container align-center">
                  {renderVideo({
                     index,
                     src: post.media.url,
                  })}
               </div>
               <div className="popup-footer">
                  <div className="popup-footer-name">{post.media.photographer}</div>
                  <div>REPRESENTED BY <span className='popup-footer-agency'>{post.media.agencies.join(' / ')}</span></div>
                  <div>{post.media.clientName} - {post.media.clientDate}</div>
               </div>
            </div>
         )
      } else {
         return (
            <div>
               <h3>{post.photographer}</h3>
               <div className="popup-image-container">
                  <div className={"image-block bank-block"} style={{ backgroundImage: `url(${post.media.url})` }}>
                     <Link to='#'>
                     </Link>
                  </div>
                  <div className="popup-footer">
                     <div className="popup-footer-name">{post.media.photographer}</div>
                     <div>REPRESENTED BY <span className='popup-footer-agency'>{post.media.agencies.join(' / ')}</span></div>
                     <div>{post.media.clientName} - {post.media.clientDate}</div>
                  </div>
                  { /* JSON.stringify(pageState.selectedMediaInPopup) */}
               </div>
            </div>
         );
      }
   };

   const renderPopup = () => {
      if (!pageState.popupIsOpen) {
         return null;
      }

      const isMobile500 = dimensions.width < 500;

      const footerContent = (
         <>
            <div className='crawler-history-popup-footer-buttons'></div>
         </>
      );

      if (isMobile500) {
         setPageState((prev) => ({
            ...prev,
            selectedMediaInPopup: null,
            popupIsOpen: false,
         }));
      }

      if (!isMobile500) {
         return (
            <Popup closePopup={onClosePopup}>
               <div className='media-bank-popup__header'>
                  <div ref={icon} className="media-bank-popup__close-btn-container" onClick={onClosePopup}>
                     <GrFormClose cursor={"pointer"} fontSize={30} className="media-bank-popup__close-btn" />
                  </div>
               </div>
               <div className='media-bank-popup__body'>
                  <div className='media-bank-popup__content'>
                     {renderMediaInPopup()}
                  </div>
                  <div className='media-bank-popup__footer'>
                     {footerContent}
                  </div>
               </div>
            </Popup>
         );
      }
   };

   /* -------- */

   const renderVideo = (values) => {
      let canPlay = true;

      if (playingVideoId && values.id !== playingVideoId) {
         canPlay = false;
      }

      return (
         <Video
            canPlay={canPlay}
            height={500}
            width={380}
            type="video/mp4"
            src={values.src}
            onPlayStart={() => {
               setPlayingVideoId(values.id);
            }}
         />
      );
   };

   const gerateAgencyString = (agencies) => {
      let string = "";
      if (agencies.length <= 0) {
         return string;
      }

      agencies.map(agencyName => {
         string += "[" + agencyName + "]";
      })

      return string;

   }

   const renderContent = () => {
      const content = mediaData.data.map((post, index) => {
         if (post.media.mediaType === "video") {
            console.log(post);

            const isMobile500 = dimensions.width < 500;

            if (isMobile500) {
               //<Link onClick={() => { onOpenPopup(post) }} to='#'>
               return (
                  <div key={index}>
                     <div>
                        <div className="image-block bank-block align-center">
                           {renderVideo({
                              index,
                              src: post.media.url,
                           })}
                        </div>
                        <div className="image-footer" onClick={() => { onOpenPopup(post) }}>
                           <div className="image-content">{post.media.photographer} {gerateAgencyString(post.media.agencies)} {post.media.clientName} {post.media.clientDate}</div>
                        </div>
                     </div>
                  </div>
               )
            } else {
               return (
                  <div key={index}>
                     <div className={"image-block bank-block"} style={{ backgroundImage: `url(${post.media.mediaPreviewUrl})` }}>
                        <div className="play-btn-container">
                           <FiPlay />
                        </div>
                        <Link onClick={() => { onOpenPopup(post) }} to='#' />
                        <div className="image-footer" onClick={() => { onOpenPopup(post) }}>
                           <div className="image-content">{post.media.photographer} {gerateAgencyString(post.media.agencies)} {post.media.clientName} {post.media.clientDate}</div>
                        </div>
                     </div>
                  </div>
               )
            }
         } else {
            return (
               <div key={index}>
                  <div className={"image-block bank-block"} style={{ backgroundImage: `url(${post.media.url})` }}>
                     <Link onClick={() => { onOpenPopup(post) }} to='#' />
                     <div className="image-footer" onClick={() => { onOpenPopup(post) }}>
                        <div className="image-content">{post.media.photographer} {gerateAgencyString(post.media.agencies)} {post.media.clientName} {post.media.clientDate}</div>
                     </div>
                  </div>
               </div>
            );
         }
      });


      const totalMedia = mediaData.data.length || 0
      const hasMore = totalMedia < mediaData.totalEntries

      return (
         <InfiniteScroll
            dataLength={totalMedia}
            next={() => {
               setPage((prev) => prev + 1)
               onLoadMediaData({
                  ...queryParams,
                  isNextPage: true,
               })
            }}
            hasMore={hasMore}
            loader={<Loading />}
         >
            <div className="bank__blocks-view">
               {content}
            </div>
         </InfiniteScroll>
      );
   };

   const renderPageContent = () => {
      function renderForm() {
         const initialValues = {
            creative_categories: queryParams.creative_categories,
            name_artist: "",
            artist: queryParams.artist,
            agency: queryParams.agency,
            client: queryParams.client,
            tags: queryParams.tags,
            media_type: queryParams.media_type,
            year: queryParams.year,
            month: queryParams.month,
            region: queryParams.region
         };

         return (
            <BankSearchForm
               initialValues={initialValues}
               creativeCategoriesOptions={creativeCategories.data}
               artistOptions={advancedArtistsData.data}
               agencyOptions={agenciesData.data}
               clientOptions={clientsData.data}
               tagsOptions={tagsData.data}
               mediaTypeOptions={mediaTypesData.data}
               yearOptions={yearsData.data}
               monthOptions={monthsData.data}
               regionOptions={regionsData.data}
               onArtistNameChange={onFieldArtistNameChange}
               onCreativeCategoriesChange={onCreativeCategoriesChange}
               onArtistChange={onArtistChange}
               onAgencyChange={onAgencyChange}
               onClientChange={onClientChange}
               onTagsChange={onTagsChange}
               onMediaTypeChange={onMediaTypeChange}
               onYearChange={onYearChange}
               onMonthChange={onMonthChange}
               onRegionChange={onRegionChange}
               totalEntries={mediaData.totalEntries}
            />
         )

      }

      return (
         <section className="bank">
            <div className="heading">
               <h1>The Bank</h1>
               <h2>FASHION</h2>
            </div>
            <div className="bank-filter">
               {renderForm()}
            </div>
            {!mediaData.isLoaded ? <Loading /> : renderContent()}
         </section>
      );
   };

   /* -------- */

   return (
      <>
         {renderPopup()}

         <div className="bank-page">
            {renderPageContent()}
         </div>
      </>
   );
};

export default BankContainer;
