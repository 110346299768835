import React, { useEffect, useState } from "react";

import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import Video from "../video/index.js";

import "./image-block.scss";

const ImageBlock = (props) => {
   const getMediaUrl = () => {
      if (props.artistType === "agency") {
         return `/rep/${props.href}`;
      }

      if (props.artistType === "photographer") {
         return `/profile/${props.href}`;
      }

      return `/${props.href}`;
   };

   /* ---------- */

   const [playingVideoId, setPlayingVideoId] = useState(null);

   const renderImageHeader = () => {
      if (props.blockType === "list") {
         return (
            <div className="image-header" />
         );
      }

      let content = null;

      if (props.blockType === "mag" || props.blockType === "awards") {
         content = (
            <div className="image-subtitle">
               {props.subtitle}
            </div>
         );
      }

      return (
         <div className="image-header">
            <h3 className={props.blockType}>
               {props.title}
            </h3>
            {content}
            <div className="info">
               {props.info}
            </div>
         </div>
      );
   }

   const renderImageFooter = () => {
      if (props.blockType === "list" || props.blockType === "bank") {
         return (
            <div className="image-footer">
               <div className="image-subtitle">
                  {props.subtitle}
               </div>
               <div className="info">{props.info}
                  <span>
                     {props.location}
                  </span>
               </div>
            </div>
         );
      }

      if (props.blockType === "bank" || props.blockType === "awards" || props.blockType === 'club' || props.blockType === "featured") {
         return (
            <div className="image-footer">
               {props.subtitle}
            </div>
         );
      }

      return null;
   }

   /* ---------- */

   const mediaUrl = getMediaUrl();

   const renderVideo = (values) => {
      let canPlay = true;

      if (playingVideoId && values.id !== playingVideoId) {
         canPlay = false;
      }

      return (
         <Video
            canPlay={canPlay}
            height={500}
            width={380}
            type="video/mp4"
            src={values.src}
            onPlayStart={() => {
               setPlayingVideoId(values.id);
            }}
         />
      );
   };

   if (props.blockType === "list") {
      let contentAgencyLogo = '';

      if (props.artistType === "agency" && props.logoUrl) {
         contentAgencyLogo = (
            <div className="logo-wrap">
               <div className="logo">
                  <img
                     src={props.logoUrl}
                     alt="Logo"
                  />
               </div>
            </div>
         );
      }

      let contentMedia = '';
      const mediaId = props.mediaId;
      const mediaType = props.mediaType;

      if (mediaType === "video") {
         contentMedia = (
            <>
               <Link to={mediaUrl}>
                  <div className="media-from-list">
                     {contentAgencyLogo}
                     {renderVideo({
                        mediaId,
                        src: props.backgroundUrl,
                     })}
                  </div>
               </Link>
            </>
         )
      } else {
         contentMedia = (
            <Link to={mediaUrl}>
               <div className="media-from-list">
                  {contentAgencyLogo}
                  <div
                     className={`image-block ${props.blockType}-block`}
                     style={{
                        backgroundImage: `url(${props.backgroundUrl})`
                     }}
                  />
                  {renderImageHeader()}
                  {renderImageFooter()}
               </div>
            </Link>
         );
      }

      return (
         <>
            {contentMedia}
         </>
      );
   }

   if (props.blockType === "club" && props.mobile === false) {
      let contentAgencyLogo = '';

      if (props.logoUrl) {
         contentAgencyLogo = (
            <div className="logo-wrap">
               <div className="logo">
                  <img
                     src={props.logoUrl}
                     alt="Logo"
                  />
               </div>
               <div className="club-media-title">AGENCY X</div>
               <div className="club-member">MEMBER AGENCY</div>
               <div className="club-location">LONDON . NEW YORK . LOS ANGELES</div>
            </div>
         );
      }

      return (
         <div className="club-media">
            {contentAgencyLogo}
            <div
               className={`image-block ${props.blockType}-block`}
               style={{
                  backgroundImage: `url(${props.backgroundUrl})`
               }}
            >
               <Link to={mediaUrl}>
                  {renderImageHeader()}
                  {renderImageFooter()}
               </Link>
            </div>
         </div>
      )
   }

   return (
      <div
         className={`image-block ${props.blockType}-block`}
         style={{
            backgroundImage: `url(${props.backgroundUrl})`
         }}
      >
         <Link to={mediaUrl}>
            {renderImageHeader()}
            {renderImageFooter()}
         </Link>
      </div>
   );
}

ImageBlock.defaultProps = {
   backgroundUrl: "",
   logoUrl: "",
   artistType: "",
   blockType: "",
   title: "",
   subtitle: "",
   info: "",
   href: "",
};

export default ImageBlock;
