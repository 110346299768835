import React, { useState, useEffect } from "react";

import "./mag.scss";
import magFormImg1 from "../../img/the-mag-form-placeholder1.png";
import { getMagForSearch } from '../../api/index'
import MagSearchForm from '../../base/forms/mag-search'

const Mag = () => {
   const [mediaData, setMediaData] = useState({ isLoaded: false, error: null, data: [] })
   const [isMain, setIsMain] = useState(true)
   const [activeFilters, setActiveFilters] = useState(null)
   const [filterCategory, setFilterCategory] = useState(null)

   async function onLoadMedia() {
      let error = null

      const res = await getMagForSearch()

      if (!res.ok) {
         error = res.error || "Error!";
      }

      //NOTE: DUMMY
      const data = res.data.entries

      data.push(data[0], data[1], data[2])

      data.map((item, index) => {
         if (index === 4 || index === 9 || index === 10) {
            item.spanGrid = true
         }
      })

      setMediaData({
         isLoaded: true,
         error,
         data
      })
   }

   useEffect(() => {
      onLoadMedia()
   }, [])

   console.log(mediaData)

   function renderMenu() {
      return (
         <>
            <div onClick={(e) => openFilters('CREATIVE NEWS', e)}>CREATIVE NEWS</div>
            <div onClick={(e) => openFilters('MONDAY REPORTS', e)}>MONDAY REPORTS</div>
            <div onClick={(e) => openFilters('SPECIALS', e)}>SPECIALS</div>
            <div onClick={(e) => openFilters('INTERVIEWS', e)}>INTERVIEWS</div>
            <div onClick={(e) => openFilters('MASTERS', e)}>MASTERS</div>
            <div onClick={(e) => openFilters('PROFILES', e)}>PROFILES</div>
         </>
      )
   }

   function openFilters(type, e) {
      setIsMain(false)

      const childElement = [...e.target.parentNode.children]
      childElement.map(item => item.style.color = 'black')

      if (type === filterCategory) {
         setIsMain(true)
         setFilterCategory(null)
         return
      }

      if (type === 'CREATIVE NEWS') {
         e.target.style.color = 'rgba(5, 0, 255, 1)'
         setActiveFilters({
            options: {
               a: 1
            }
         })
         setFilterCategory('CREATIVE NEWS')
      } else if (type === 'MONDAY REPORTS') {
         e.target.style.color = 'rgba(5, 0, 255, 1)'
         setActiveFilters({
            options: {
               a: 1
            }
         })
         setFilterCategory('MONDAY REPORTS')
      } else if (type === 'SPECIALS') {
         e.target.style.color = 'rgba(5, 0, 255, 1)'
         setActiveFilters({
            options: {
               a: 1
            }
         })
         setFilterCategory('SPECIALS')
      } else if (type === 'INTERVIEWS') {
         e.target.style.color = 'rgba(5, 0, 255, 1)'
         setActiveFilters({
            options: {
               a: 1
            }
         })
         setFilterCategory('INTERVIEWS')
      } else if (type === 'MASTERS') {
         e.target.style.color = 'rgba(5, 0, 255, 1)'
         setActiveFilters({
            options: {
               a: 1
            }
         })
         setFilterCategory('MASTERS')
      } else if (type === 'PROFILES') {
         e.target.style.color = 'rgba(5, 0, 255, 1)'
         setActiveFilters({
            options: {
               a: 1
            }
         })
         setFilterCategory('PROFILES')
      }
   }

   function renderMenuFilters() {
      return (
         <MagSearchForm activeFilters={activeFilters} />
      )
   }

   function renderContent() {
      return (
         <div className={`mag-media ${isMain ? 'main' : 'filtered'}`}>
            {mediaData.data.map((post, index) => (
               <div key={index} className={`${post.spanGrid ? 'span-grid' : ''}`}>
                  <div className="image-block" style={{ backgroundImage: `url(${post.mediaUrl})` }}>
                  </div>
               </div>
            ))}
         </div>
      )
   }

   return (
      <div className="mag-page">
         <section>
            <div className='heading'>
               <h1>The Mag</h1>
               <p className="subtitle">THE LATEST INSPIRING NEWS</p>
            </div>
            <div className="mag-menu">
               {renderMenu()}
            </div>
            <div className={`${isMain ? 'mag-menu-filters-container hide' : 'mag-menu-filters-container'}`}>
               {renderMenuFilters()}
            </div>
            <div className="mag-content-section">
               {renderContent()}
            </div>
         </section >
      </div >
   );
}

export default Mag;