import React, { useEffect, useState } from "react";

import Section from "../Section";

import { getBankForHomepage } from "../../api";
import "./bank-section.scss";

const BankSection = () => {

   const [bankData, setBankData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const loadBankData = () => {
      getBankForHomepage().then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         const dummyData = res.data.entries.map((item) => {
            return {
               ...item,
               slug: ''
            }
         })

         console.log(dummyData)

         setBankData({
            isLoaded: true,
            error,
            data: dummyData,
         });
      })
   }

   useEffect(() => {
      loadBankData();
   }, []);

   return (
      <Section
         isLoaded={bankData.isLoaded}
         error={bankData.error}
         data={bankData.data}
         blockType="bank"
         title="The Bank"
         subtitle="THE ULTIMATE MEDIA SEARCH ENGINE"
      />
   );

}

export default BankSection;