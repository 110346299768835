import React from "react";
import { Formik } from "formik";

import SelectCustom from "../../../components/forms/select/index.js";
import Input from "../../../components/forms/input/index.js";

import * as styles from "./styles.module.css";


const ListSearchForm = (props) => {
    const renderForm = (formProps) => {
        const {
            values,
            handleChange,
            handleBlur,
            setFieldValue,
            handleSubmit,
        } = formProps;

        return (
            <form
                className={styles.form}
                onSubmit={handleSubmit}
            >
                <div className={styles.formNameField}>
                    <Input
                        name="name"
                        label="name"
                        placeholder="Search By Name"
                        value={values.name}
                        onChange={(evt) => {
                            handleChange(evt);
                            props.onNameChange(evt.target.value);
                        }}
                        onBlur={handleBlur}
                    />
                </div>
                {(function() { if(!(props.filtersIsClose) && props.filtersIsClose !== null) {
                    return (
                        <div className={styles.formFilters}>
                            <SelectCustom
                                title="Who"
                                name="who"
                                options={props.whoOptions}
                                selected={values.who}
                                onChange={(value) => {
                                    setFieldValue("who", value);
                                    props.onWhoChange(value);
                                }}
                            />
                            <SelectCustom
                                title="What"
                                name="what"
                                options={props.whatOptions}
                                selected={values.what}
                                onChange={(value) => {
                                    setFieldValue("what", value);
                                    props.onWhatChange(value);
                                }}
                            />
                            <SelectCustom
                                title="Where"
                                name="where"
                                options={props.whereOptions}
                                selected={values.where}
                                onChange={(value) => {
                                    setFieldValue("where", value);
                                    props.onWhereChange(value);
                                }}
                            />
                        </div>
                    )
                  }
                })()}
            </form>
        );
    };

    return (
        <Formik
            enableReinitialize
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
        >
            {renderForm}
        </Formik>
    );
};

ListSearchForm.defaultProps = {
    initialValues: {},
    whoOptions: [],
    whatOptions: [],
    whereOptions: [],

    onNameChange: () => {},
    onWhoChange: () => {},
    onWhatChange: () => {},
    onWhereChange: () => {},

    onSubmit: () => {},
};

export default ListSearchForm;
