import React, { useEffect, useRef } from "react";

import * as styles from "./styles.module.css";


const Video = (props) => {
    const videoRef = useRef(null);

    useEffect(() => {
        if (!props.canPlay && videoRef.current) {
            videoRef.current.pause();
        }
    }, [props.canPlay]);

    const videoStyle = {
        height: props.height || "auto",
        width: props.width || "auto",
    };

    return (
        <video
            ref={videoRef}
            className={styles.video}
            style={videoStyle}
            controls
            onPlay={props.onPlayStart}
            onEnded={props.onPlayEnd}
            onPause={props.onPause}
        >
            <source
                src={props.src}
                type={props.type}
            />
            Your browser does not support the video tag.
        </video>
    );
};

Video.defaultProps = {
    src: "",
    type: "",
    height: "auto",
    width: "auto",
    onPlayStart: () => {},
    onPlayEnd: () => {},
    onPause: () => {},
};

export default Video;
