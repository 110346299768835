require('dotenv').config();

const WP_URL = process.env.API_URL;

// Home
export function getBankForHomepage() {
   //NOTE: DUMMY
   return fetch(WP_URL + '/wp-content/themes/theagentsclub-v2/search-bank.php?media_type=all&limit=5')
      .then((response) => response.json())
}


export function getMagForHomepage() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_mag_for_homepage')
      .then((response) => response.json())
}


export function getAwardsForHomepage() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_awards_for_homepage')
      .then((response) => response.json())
}

export function getClubForHomepage() {
   //NOTE: DUMMY
   return fetch(`${WP_URL}/wp-content/themes/theagentsclub-v2/search-list.php?limit=2`)
   .then((response) => response.json())
}

// The list
export function artistsGetAllTypes() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=artists_get_all_types')
      .then((response) => response.json())
}

export function locationsCitiesGetAll() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=locations_cities_get_all')
      .then((response) => response.json())
}

export function getAllCategories() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_all_categories')
      .then((response) => response.json())
}

export function getListForSearch(data = {}) {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_list_for_search&artist_type=' + data.artistType + '&category=' + data.category + '&location=' + data.location)
      .then((response) => response.json())
}

// Profile
export function portfolioCategoriesGetAll() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=portfolio_cateories_get_all')
      .then((response) => response.json())
}

export function portfolioYearsGetAll() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=portfolio_years_get_all')
      .then((response) => response.json())
}

export function portfolioMonthsGetAll() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=portfolio_months_get_all')
      .then((response) => response.json())
}

export function getProfileTabMedia() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_profile_tab_media')
      .then((response) => response.json())
}

// --- live data
export function getSearchBank(data = {}) {
   return fetch(`${WP_URL}/wp-content/themes/theagentsclub-v2/search-bank.php?media_type=${data.media_type}&category=${data.creative_categories}&agency=${data.agency}&artist=${data.artist}&client=${data.client}&month=${data.month}&region=${data.region}&tags=${data.tags}&year=${data.year}&page=${data.newPage}`)
      .then((response) => response.json())
}

export function getSearchList(data = {}) {
   return fetch(`${WP_URL}/wp-content/themes/theagentsclub-v2/search-list.php?type=${data.type}&category=${data.category}&location=${data.location}&name=${data.name}&page=${data.page}`)
      .then((response) => response.json())
}

export function getProfileBySlug(slug) {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_profile_by_slug&slug=' + slug)
      .then((response) => response.json())
}

// The bank
export function getBankForSearch(data = {}) {
   return fetch(`${WP_URL}/wp-admin/admin-ajax.php?action=get_bank_for_search&artis=${data.artist}&agency=${data.agency}&client=${data.client}&tags=${data.tags}&media_type=${data.mediaType}&year=${data.year}&month=${data.month}&region=${data.region}`)
      .then((response) => response.json())
}

export function getArtists() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_artists')
      .then((response) => response.json())
}

export function getAgencies() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_agencies')
      .then((response) => response.json())
}

export function getClients() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_clients')
      .then((response) => response.json())
}

export function getTags() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_tags')
      .then((response) => response.json())
}

export function getMediaTypes() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_media_types')
      .then((response) => response.json())
}

export function getYears() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_years')
      .then((response) => response.json())
}

export function getMonths() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_months')
      .then((response) => response.json())
}

export function getRegions() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_regions')
      .then((response) => response.json())
}

export function getOtherCategories() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_other_categories')
      .then((response) => response.json())
}

export function getMediaByArtist() {
   return fetch(WP_URL + '/wp-admin/admin-ajax.php?action=get_media_by_artist')
      .then((response) => response.json())
}

export function advancedArtistsSearch(data = {}) {
   //NOTE: Dummy
   return fetch(`${WP_URL}/wp-admin/admin-ajax.php?action=advanced_artists_search&type=${data.type}&search=${data.search}`)
   .then((response) => response.json())
}

// The Magazine
export function getMagForSearch() {
   //NOTE: Dummy
   return fetch(`${WP_URL}/wp-content/themes/theagentsclub-v2/search-bank.php?media_type=all`)
   .then((response) => response.json())
}