import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './popup.scss';

const propTypes = {
    children: PropTypes.node,
};


const defaultProps = {
    children: null,
};


function Popup(props) {
    const { children, closePopup } = props;

    useEffect(() => {
        window.addEventListener('keydown', closePopup)
    }, [])

    return (
        <div className='popup'>
            <div className='popup__layout' />
            <div className='popup__container' onClick={closePopup}>
                <div className='popup__content'>
                    {children}
                </div>
            </div>
        </div>
    );
}


Popup.propTypes = propTypes;
Popup.defaultProps = defaultProps;

export default Popup;
