import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Index from "../views/Index";
import NotFound from "../views/NotFound";

import Bank from "../views/Bank";
import List from "../views/List";
import Profile from "../views/Profile";
import Mag from "../views/Mag";
import Awards from "../views/Awards";
import Club from "../views/Club";

export default (
   <Switch>
      <Route exact path="/" component={Index} />

      <Route path="/bank" component={Bank} />
      <Route path="/list" component={List} />
      <Route exact path="/rep/:slug" component={Profile} />
      <Route exact path="/profile/:slug" component={Profile} />
      <Route exact path="/provider/:slug" component={Profile} />
      <Route path="/mag" component={Mag} />
      {/*<Redirect from="/magazine" to="/mag" />*/}
      <Route path="/awards" component={Awards} />
      <Route path="/club" component={Club} />

      <Route component={NotFound} />
   </Switch>
);