import React from 'react'

import './loading.scss'

import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export default function Loading() {
    return (
        <div className='content'>
            <p className='loader'>
                <AiOutlineLoading3Quarters className="loading-icon" style={{ fontSize: '50px', color: "#cacaca" }} />
            </p>
        </div>
    )
}
