import React, { useEffect, useState } from "react";

import Section from "../Section";

import { getMagForHomepage } from "../../api";
import "./mag-section.scss";

const MagSection = () => {

   const [magData, setMagData] = useState({
      isLoaded: false,
      error: null,
      data: [],
   });

   const loadMagData = () => {
      getMagForHomepage().then((res) => {
         let error = null;

         if (!res.ok) {
            error = res.error || "Error!";
         }

         setMagData({
            isLoaded: true,
            error,
            data: res.data,
         });
      })
   }

   useEffect(() => {
      loadMagData();
   }, []);

   return (
      <Section
         isLoaded={magData.isLoaded}
         error={magData.error}
         data={magData.data}
         blockType="mag"
         title="The Mag"
         subtitle="THE LATEST INSPIRING NEWS"
      />
   );

}

export default MagSection;