import React, { useEffect, useState, useMemo, useCallback, useReducer } from "react";

import Section from "../Section";

import { artistsGetAllTypes } from "../../api";
import { locationsCitiesGetAll } from "../../api";
import { getAllCategories } from "../../api";

import "./list-section.scss";
// import listFormImg from "../../img/the-list-form-placeholder.png";
// import Select from "../Form/Select/Select";
import Select from "react-select";
import Button from "../Button/Button";
import ListSearchForm from "../../base/forms/list-search/index.js";
import useQuery from "../../base/hooks/use-query/index.js";
import { useHistory } from "react-router-dom";

const formReducer = (state, event) => {
  console.log("event", event);

  return {
    ...state,
    [event.name]: event.value,
  };
};

const ListSection = () => {

  const [state, setState] = useState({
    whoSelectedOption: '',
    whatSelectedOption: '',
    whereSelectedOption: '',
  });

  const query = useQuery();
  const history = useHistory()

  const queryParams = {
    who: query.get("who") || "all",
    what: query.get("what") || "all",
    where: query.get("where") || "all",
    name: query.get("name") || "",
  };

  const getOptions = (values) => {
    const options = [];

    options.push({
      label: 'all',
      value: 'all',
    });

    for (let i = 0; i < values.length; i += 1) {
      const val = values[i];

      options.push({
        label: val.name,
        value: val.slug,
      });
    }

    return options;
  }

  const [artistsTypes, setArtistsTypes] = useState({
    isLoaded: false,
    error: null,
    data: [],
  });

  const [locationsCities, setLocationsCities] = useState({
    isLoaded: false,
    error: null,
    data: [],
  });

  const [categories, setCategories] = useState({
    isLoaded: false,
    error: null,
    data: [],
  });

  const loadArtistsTypes = () => {
    artistsGetAllTypes().then((res) => {
      let error = null;

      if (!res.ok) {
        error = res.error || "Error!";
      }

      setArtistsTypes({
        isLoaded: true,
        error,
        data: getOptions(res.types),
      });
    })
  }

  const loadLocationsCities = () => {
    locationsCitiesGetAll().then((res) => {
      let error = null;

      if (!res.ok) {
        error = res.error || "Error!";
      }

      setLocationsCities({
        isLoaded: true,
        error,
        data: getOptions(res.cities),
      });

    })
  }

  const loadCategories = () => {
    getAllCategories().then((res) => {
      let error = null;

      if (!res.ok) {
        error = res.error || "Error!";
      }

      setCategories({
        isLoaded: true,
        error,
        data: getOptions(res.categories),
      });

    })
  }


  useEffect(() => {
    loadArtistsTypes();
    loadLocationsCities();
    loadCategories();
  }, []);



  /*
  const [name, setName] = useState("123");

  <input name="name" value={name} onChange={(evt) => { setName(evt.target.value); }} />
  */

  /*
  const [formData, setFormData] = useReducer(formReducer, {
    who: "all",
    what: "all",
    where: "all",
  });
  */

  const [formData, setFormData] = useReducer(formReducer, {
    who: { label: "all", value: 'all' },
    what: { label: "all", value: 'all' },
    where: { label: "all", value: 'all' },
  });

  const [submitting, setSubmitting] = useState(false);


  const handleSubmit = (event) => {
    /*
    event.preventDefault();
    setSubmitting(true);
    
    console.log(formData);
    
    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
    */

  };

  const loadListData = (query) => {
    const {
      who,
      what,
      where,
      name
    } = query
    history.push(`/list?who=${who}&what=${what}&where=${where}&name=${name}`)
    window.scrollTo(0, 0);
  }

  const [pageState, setPageState] = useState({
    filtersIsClose: false,
  });

  const onFieldNameChange = (value) => {
    if (value.length > 0) {
      setPageState((prev) => ({
        ...prev,
        filtersIsClose: true,
      }));

      loadListData({
        ...queryParams,
        name: value,
        who: '',
        what: '',
        where: '',
        page: '',
      });
    } else {
      setPageState((prev) => ({
        ...prev,
        filtersIsClose: false,
      }));

      loadListData({
        ...queryParams,
        name: '',
      });
    }

  };

  const onFieldWhoChange = (value) => {
    loadListData({
      ...queryParams,
      who: value,
    });
  };

  const onFieldWhatChange = (value) => {
    loadListData({
      ...queryParams,
      what: value,
    });
  };

  const onFieldWhereChange = (value) => {
    loadListData({
      ...queryParams,
      where: value,
    });
  };


  const whoHandleChange = event => {
    setState((prev) => ({
      ...prev,
      whoSelectedOption: { label: event.label, value: event.value }
    }));
  };

  const whatHandleChange = event => {
    setState((prev) => ({
      ...prev,
      whatSelectedOption: { label: event.label, value: event.value }
    }));
  }

  const whereHandleChange = event => {
    setState((prev) => ({
      ...prev,
      whereSelectedOption: { label: event.label, value: event.value }
    }));
  }

  const selectDefaultValue = {
    label: 'all',
    value: 'all',
  };

  const filterStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: '#fff',
      borderColor: '',
      borderRadius: '',
      borderStyle: '',
      borderWidth: '',
      backgroundColor: '#f592e0',
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '15px',
      letterSpacing: '0.05em',
      textAlign: 'left',
      textTransform: 'none',
      width: '200px',

    }),

    option: styles => ({
      ...styles,
      overflowY: 'hidden',
      overflowX: 'hidden',
      backgroundColor: '#f592e0',
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '15px',
      letterSpacing: '0.05em',
      textAlign: 'left',
      textTransform: 'none',
    }),
  };

  const renderForm = () => {
    const initialValues = {
      name: queryParams.name,
      who: queryParams.who,
      what: queryParams.what,
      where: queryParams.where,
    };

    return (
      <ListSearchForm
        initialValues={initialValues}
        whoOptions={artistsTypes.data}
        whatOptions={categories.data}
        whereOptions={locationsCities.data}
        onNameChange={onFieldNameChange}
        onWhoChange={onFieldWhoChange}
        onWhatChange={onFieldWhatChange}
        onWhereChange={onFieldWhereChange}
        filterStyles={filterStyles}
      />
    );
  };

  // <Select name="" options={[]} onChange={handleChange}/>
  return (
    <section className="list_block">
      <h2 className="list">The List</h2>
      <p className="subtitle">THE VISUAL CREATIVE DIRECTORY</p>
      {/* <img src={listFormImg} alt="List Form" /> */}
      <div className="list_block__search">
        <h3 className="list_block_title_who">Who's who</h3>
        <p className="list_block_subtitle_who">REPRESENTATIVES . PHOTOGRAPHERS . DIRECTORS . DIGITAL ARTISTS . ILLUSTRATORS . SERVICE PROVIDERS</p>
        <div className="list__form">
          {submitting && (
            <div>
              You are submitting the following:
              <ul>
                {Object.entries(formData).map(([name, value]) => (
                  <li key={name}>
                    <strong>{name}</strong>:{value && value.toString()}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {renderForm()}
        </div>
      </div>
    </section>
  );
};

export default ListSection;
