.form {
    display: grid;
}

.formNameField {
    max-width: 400px;
    justify-self: center;
}

.formFilters {
    display: grid;

    margin-top: 60px;
}

@media only screen and (min-width: 800px) {
    .formFilters {
        grid-auto-flow: column;
        grid-gap: 20px;
    }
}
