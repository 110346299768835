import React from 'react'
import { Formik, Form, Field } from "formik";

export default function NewsSignUp({children}) {
    return (
        <Formik
          initialValues={{ email: ''}}
          onSubmit={(values) => {
              console.log(values)
          }}
        >{({isSubmitting}) => (
            <Form className='news-sign-up-form'>
                {children}
                <div className='news-sign-up-input-container'>
                   <Field type='email' name='email' placeholder='xxxxxxxxxx' />
                   <button type='submit'>
                       SIGN UP & PREFERENCES
                   </button>
                 </div>
            </Form>
        )}
        </Formik>
    )
}
